import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Cart from "../Contexts/CartContext";
import UploadProgressModal from "../Modals/UploadProgressModal";
import * as Icon from "./Icon";
import * as Theme from "../Theme";
import MultipartUploader from "./MultipartUploader";
const Constants = require("../constants");

const STEPS = {
  GET_JWT: 0,
  PREPARE: 1,
  UPLOAD: 2,
  UPDATE_ITEM: 3,
};

const INIT_STATE = {
  loading: false,
  step: STEPS.GET_JWT,
  percentage: 0,
  url: "",
};

const StyledUploadDragger = styled(Ant.Upload.Dragger)`
  && {
    margin-top: 16px;
    .ant-upload {
      background-color: white;
    }
    .ant-upload-drag-container {
      padding: 24px;
      border-radius: 6px;
    }
  }
`;

const StyledUpload = styled(Ant.Upload)`
  && {
    .ant-upload.ant-upload-select {
      display: block;
    }
  }
`;

const StyledButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 44px;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  margin-top: 8px;
  background-color: ${Constants.buttons.background.normal.primary};
`;

const StyledUploadBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  width: 100%;
  padding: 12px 44px 12px 16px;
  border-radius: 10px;
  font-size: 16px;
  color: #25272c;
  background: ${props => (props.status === "error" ? "#FEF6F6" : "#F0FAF0")};
  border: 1px solid
    ${props => (props.status === "error" ? "#E2341D" : "#41AE49")};

  > img.upload {
    position: absolute;
    right: 16px;
  }
`;

function sliceFilename(file) {
  file = decodeURIComponent(file);
  return (file && file.slice(file.lastIndexOf("/") + 1)) || "";
}

class UploadAttachmentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INIT_STATE,
    };

    this.uploader = new MultipartUploader({
      host: Constants.revStorageHost,
      debug: Constants.debug,
      onProgress: percentage => this.setState({ percentage }),
      onStart: () => this.setState({ step: STEPS.UPLOAD }),
      onComplete: ({ url }) => {
        this.setState({ url });
      },
    });
  }

  componentWillUnmount() {
    this.uploader.close();
  }

  disabledUpload = () => {
    const { paymentStatus, status } = this.props;
    if (
      paymentStatus === "success" &&
      status !== "waiting" &&
      status !== "rejected"
    ) {
      return true;
    }
    if (paymentStatus === "waiting") {
      return true;
    }
    return false;
  };

  render() {
    let { loading, step, percentage } = this.state;
    let { status, attachment, paymentStatus } = this.props;

    return (
      <Ant.Spin spinning={loading}>
        {status === "waiting" ? (
          <StyledUploadDragger
            fileList={[]}
            name="file"
            beforeUpload={file => false}
            onChange={this._upload}
          >
            {paymentStatus === "waiting" ? (
              <p>請於付款後上傳稿件</p>
            ) : (
              <>
                <p>將稿件拖動到這裡或是</p>
                <StyledButton>上傳稿件</StyledButton>
              </>
            )}
          </StyledUploadDragger>
        ) : (
          <StyledUpload
            fileList={[]}
            name="file"
            beforeUpload={file => false}
            onChange={this._upload}
            disabled={
              paymentStatus === "success" &&
              status !== "waiting" &&
              status !== "rejected"
            }
          >
            {status === "rejected" ? (
              <StyledUploadBar status="error">
                <img src="./images/order/file-error.svg" alt="" />
                {sliceFilename(attachment?.file)}
                <img
                  className="upload"
                  src="./images/order/refresh.svg"
                  alt=""
                />
              </StyledUploadBar>
            ) : (
              <StyledUploadBar status="success">
                <img src="./images/order/file.svg" alt="" />
                {sliceFilename(attachment?.file)}
                {paymentStatus !== "success" && (
                  <img
                    className="upload"
                    src="./images/order/edit.svg"
                    alt=""
                  />
                )}
              </StyledUploadBar>
            )}
          </StyledUpload>
        )}
        <UploadProgressModal
          show={loading}
          step={step}
          percentage={percentage}
          onCancel={this._reset}
        />
      </Ant.Spin>
    );
  }

  _upload = async info => {
    let { onUpdate, editOrderItem } = this.props;

    try {
      let file = info.file; //.originFileObj; // put antd file into uppy would throw error

      let { appActions } = this.props;

      // step 1: get JWT token
      this.setState({ loading: true });
      let { token } = await appActions.getJwtToken();
      this.uploader.setToken(token);

      // step 2 add file to uppy
      this.setState({ step: STEPS.PREPARE });

      let result = await this.uploader.upload(file);
      console.log("RETURN FROM UPLOAD", result);

      if (result) {
        if (result.successful) {
          Ant.message.success("上傳成功");
        } else {
          console.warn("Errors: ", result.failed);
          Ant.message.error("上傳失敗");
        }

        // step 4: update order_item attachment
        await editOrderItem({ url: this.state.url });

        onUpdate();
      }
    } catch (err) {
      console.warn(err);
    }

    this._reset();
  };

  _reset = () => {
    this.uploader.reset();
    this.setState({ ...INIT_STATE });
  };
}

export default connect(null, ActionCreator)(UploadAttachmentButton);
